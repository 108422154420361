<template>
  <el-container>
    <el-aside width="auto">
      <span class="myinput">
        <el-input placeholder="输入关键字进行过滤" v-model="filterText">
        </el-input>
      </span>
      <span class="mytree">
        <el-tree v-if="loadFinish === true" :highlight-current="highlightCurrent" :data="data" :props="defaultProps"
          @node-click="handleNodeClick" ref="tree" accordion :filter-node-method="filterNode"></el-tree>
      </span>
    </el-aside>
    <el-main>
      <div class="mytab">
        <el-tabs tab-position="left">
          <el-tab-pane label="报警手册">
            <el-collapse v-model="activeNames" @change="handleChange" accordion>
              <el-collapse-item v-for="item in alarminfo" :key="item.id"
                :title="item.msg + '   (故障代码:  E' + item.code + ')'" :name="item.code"
                :class='item.id % 2 === 0 ? "odd_step" : "even_step"'>
                <el-row :span="24" :gutter="10">
                  <!-- <el-col :span="24"><div><span class="page">解决方法:</span> {{item.resolve}}</div></el-col> -->
                  <el-col :span="24">
                    <el-steps :active="item.resolve.length" align-center>
                      <el-step v-for="(alarm,index) in item.resolve" :key="index" :title="alarm"></el-step>
                    </el-steps>
                  </el-col>
                </el-row>
              </el-collapse-item>
            </el-collapse>
          </el-tab-pane>
          <el-tab-pane label="历史报警">
            <div class="mytable">
              <el-input v-model="search" placeholder="输入关键字进行过滤" />
              <el-table :data="tableData.filter(data => !search || data.alarmcontent.toLowerCase().includes(search.toLowerCase()) 
                              || data.alarmid.toString().toLowerCase().includes(search.toLowerCase())
                              || data.alarmcode.toString().toLowerCase().includes(search.toLowerCase())
                              || data.alarmtime.toLowerCase().includes(search.toLowerCase()))" style="width: 100%"
                :border="border" highlight-current-row>
                <el-table-column type="index" align="center" header-align="center" label="索引号" width="100">
                </el-table-column>
                <el-table-column align="center" header-align="center" prop="alarmtime" label="报警日期/时间" min-width="180">
                </el-table-column>
                <el-table-column align="center" header-align="center" prop="alarmid" label="报警ID号" width="180">
                </el-table-column>
                <el-table-column align="center" header-align="center" prop="alarmcode" label="报警代码" width="180">
                </el-table-column>
                <el-table-column align="center" header-align="center" prop="alarmcontent" label="报警内容" min-width="180">
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
          <el-tab-pane label="报警设置">
            <div class="alarmsetting">
              <el-descriptions :column="4" border v-for="item in alarmsnsinfo" :key="item.id">
                <el-descriptions-item label="ID" label-class-name="my-id-label" content-class-name="my-id-content">
                  {{item.id}}</el-descriptions-item>
                <el-descriptions-item label="编码" label-class-name="my-code-label" content-class-name="my-code-content">
                  E{{item.code}}</el-descriptions-item>
                <el-descriptions-item label="内容" label-class-name="my-msg-label" content-class-name="my-msg-content">
                  {{item.msg}}
                  <!-- <el-tag size="small"></el-tag> -->
                </el-descriptions-item>
                <el-descriptions-item label="设置" label-class-name="my-set-label" content-class-name="my-set-content">
                  {{item.stopset === 0 ? "报警并停机" : item.stopset === 1 ? "报警但不停机" : "报警并暂停"}}</el-descriptions-item>
              </el-descriptions>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import { getDeviceInfo, getTitleNoList } from '../../axios/data.js'
export default {
  name: "historyalarm",
  data () {
    return {
      dateYear: '',
      dateMonth: '',
      alignFormat: 'right',
      clearable: false,
      editable: false,
      loading: null,
      loadText: '',
      titlenoList: [],
      filterText: '',
      data: [],
      tableData: [],
      loadFinish: false,
      highlightCurrent: true,
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      curLabel: '',
      border: true,
      search: '',
      alarminfo: [],
      activeNames: ['1'],
      alarmsnsinfo: []
    }
  },
  watch: {
    filterText (val) {
      if (this.$refs.tree !== undefined) {
          this.$refs.tree.filter(val);
      }

    },
    windowHeight (val) {
      let that = this;
      console.log("实时屏幕高度：", val, that.windowHeight);
      this.refreshStyle()
    },
    windowWidth (val) {
      let that = this;
      console.log("实时屏幕宽度：", val, that.windowHeight);
      this.refreshStyle()
    }
  },
  computed: {
    domain () {
      return this.$store.state.tab.domain
    },
    domain443 () {
      return this.$store.state.tab.domain443
    },
    windowWidth () {
      return this.$store.state.tab.windowWidth
    },
    windowHeight () {
      return this.$store.state.tab.windowHeight
    },
    titleno () {
      return this.$store.state.tab.titleno
    },
    titlelist () {
      return this.$store.state.user.titlelist
    }
  },
  mounted () {
    this.onMounted()
  },
  methods: {
    checkStar(titleList) {
        return titleList.indexOf("*") !== -1
    },
    refreshStyle () {
      var MyTable = document.querySelectorAll('.mytable')
      for (var i = 0; i < MyTable.length; i++) {
        MyTable[i].style.minHeight = 80 + 'vh'
      }

      var idLabel = document.querySelectorAll('.my-id-label')
      for (i = 0; i < idLabel.length; i++) {
        idLabel[i].style.width = 10 + 'vw'
      }

      var idContent = document.querySelectorAll('.my-id-content')
      for (i = 0; i < idContent.length; i++) {
        idContent[i].style.width = 10 + 'vw'
      }

      var codeLabel = document.querySelectorAll('.my-code-label')
      for (i = 0; i < codeLabel.length; i++) {
        codeLabel[i].style.width = 10 + 'vw'
      }

      var codeContent = document.querySelectorAll('.my-code-content')
      for (i = 0; i < codeContent.length; i++) {
        codeContent[i].style.width = 10 + 'vw'
      }

      var msgLabel = document.querySelectorAll('.my-msg-label')
      for (i = 0; i < msgLabel.length; i++) {
        msgLabel[i].style.width = 15 + 'vw'
      }

      var msgContent = document.querySelectorAll('.my-msg-content')
      for (i = 0; i < msgContent.length; i++) {
        msgContent[i].style.width = 15 + 'vw'
      }

      var setLabel = document.querySelectorAll('.my-set-label')
      for (i = 0; i < setLabel.length; i++) {
        setLabel[i].style.width = 15 + 'vw'
      }

      var setContent = document.querySelectorAll('.my-set-content')
      for (i = 0; i < setContent.length; i++) {
        setContent[i].style.width = 15 + 'vw'
      }
    },
    openFullScreen () {
      this.loading = this.$loading({
        lock: true,
        text: this.loadText,
        spinner: 'el-icon-loading',
        customClass: 'create-isLoading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    },
    closeFullScreen () {
      this.loading.close();
    },
    async getTitleList () {
      this.loadText = '正在向服务器请求数据, 请稍候~~'
      this.openFullScreen()
      try {
        const res = await getTitleNoList(this.domain)
        var respText = JSON.parse(res.request.responseText);
        console.log(respText)
        if (respText.status === "true") {
          var isArray = respText.message.constructor === Array
          if (isArray) {
            for (var i = 0; i < respText.message.length; i++) {
              let isvalid = this.checkStar(this.titlelist)
              if(!isvalid)
              {
                 isvalid = this.titlelist.indexOf(respText.message[i].titleno) !== -1
              }
              if(isvalid)
              {
                 this.titlenoList.push(respText.message[i].titleno)
              }
            }
          }
          else {
            var isObject = respText.message.constructor === Object
            if (isObject) {
              let isvalid = this.checkStar(this.titlelist)
              if(!isvalid)
              {
                 isvalid = this.titlelist.indexOf(respText.message.titleno) !== -1
              }
              if(isvalid)
              {
                 this.titlenoList.push(respText.message.titleno)
              }
            }
          }
        }

        for (i = 0; i < this.titlenoList.length; i++) {
          const yearstring = this.titlenoList[i].substring(0, 4)
          const year = parseInt(yearstring)
          if (year >= 2000 && year <= 2100) {
            var isexist = false, j = 0
            for (j = 0; j < this.data.length; j++) {
              if (this.data[j].label === yearstring) {
                isexist = true
                break
              }
            }

            if (!isexist) {
              var obj = {
                label: yearstring,
                year: yearstring,
                children: [{
                  label: this.titlenoList[i],
                  year: yearstring,
                  children: []
                }]
              }
              this.data.push(obj)
            }
            else {
              obj = {
                label: this.titlenoList[i],
                year: yearstring,
                children: []
              }
              this.data[j].children.push(obj)
            }
          }
        }
        this.closeFullScreen()
      } catch (error) {
        this.$message.warning(error)
        this.closeFullScreen()
      }

      this.loadFinish = true
      console.log(this.titlenoList)
      console.log(this.titleno)
      console.log('data', this.data)

      if (this.titleno === '') {
        if (this.data.length > 0) {
          if (this.data[0].children && this.data[0].children.length > 0)
            var titleno = this.data[0].children[0].label
          this.$store.commit('updateTitleNo', titleno)
          this.filterText = titleno
          await this.getHistoryInfo(titleno)
        }
      } else {
        await this.getHistoryInfo(this.titleno)
        this.filterText = this.titleno
      }
      setTimeout(() => {
        this.refreshStyle()
      }, 500)
    },
    onMounted () {
      this.getTitleList()
      //this.getFileNode()
    },
    filterNode (value, data) {
      //console.log(data)
      if (!value) return true;
      return data.label.indexOf(value) !== -1 || data.year.indexOf(value) !== -1;
    },
    handleNodeClick (data) {
      if (data.label.length === 4) {
        var year = parseInt(data.label)
        if (year >= 2000 && year <= 2100) return
      }
      if (data.label === this.titleno) return;
      console.log("historyalarm store commit")
      this.$store.commit('updateTitleNo', data.label)
      this.filterText = ''
      this.getHistoryInfo(data.label)
    },
    async getHistoryInfo (titleno) {
      this.openFullScreen();
      this.tableData = []
      try {
        var params = new Object()
        params.titleno = titleno
        var res = await getDeviceInfo(this.domain, params)
        this.loadText = '正在向服务器请求数据, 请稍候~~'
        var respText = JSON.parse(res.request.responseText);
        if (respText.status === "true") {
          var tableData = []
          var historyalarminfo = JSON.parse(respText.message.historyalarminfo)
          historyalarminfo.forEach(item => {
            tableData.push(item)
          })
          this.tableData = tableData
          this.alarmsnsinfo = JSON.parse(respText.message.alarmsnsinfo)
          //console.log(this.alarmsnsinfo)
          var alarminfo = JSON.parse(respText.message.alarminfo)

          alarminfo.forEach(item => {
            var desr = item.resolve.split("\n").filter(item => item != '')
            //console.log('desr', desr)
            item.resolve = desr
          })
          //console.log(alarminfo)
          this.alarminfo = alarminfo
          //console.log(this.alarminfo)
        }
        this.closeFullScreen()
      } catch (error) {
        this.closeFullScreen()
        this.$message.warning(error)
      }
    },
    handleChange (val) {
      console.log(val);
    }
  }
}
</script>

<style lang='scss' scoped>
.boxes {
  display: flex;
  flex-direction: column;
}
.box {
  width: 41.667vw;
  margin: 1.042vw;
  left: 50%;
  transform: translateX(-50%);
}
.block,
.block1,
.block2 {
  margin-top: 0.521vw;
  margin-bottom: 1.563vw;

}
.el-icon-info {
  margin-right: 0.521vw;
  font-size: 2.083vw;
  transform: translateY(0.26vw);
}
.el-main {
  padding-top: 0px;
  background-color: #1389bc;
}
.el-container {
  height: 100%;
  background-color: #333;
  border: 1px solid #333;
  margin-top: 0.042vw;
  margin-bottom: 1.042vw;
}

.page {
  color: #dbe276;
  background-color: #1f1f1f;
  border-radius: 0.26vw;
  margin: 1.042vw;
  padding: 0.26vw;
  width: 10.417vw;
  height: 1.563vw;
  line-height: 1.563vw;
}

.mytab ::v-deep {
  .el-tabs__item {
    margin-top: 15px;
    padding: -1px 0.521vw;
    margin-bottom: 15px;
    
    // border: 1px solid #ccc;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 60px;
    line-height: 5;
    font-size: 12px;
    font-weight: 400;
    background-color: aquamarine;
    color: #333;
    text-align: center;
    border-radius: 10px;
  }

  .el-tabs__item.is-active {
    color: green; //批改激活表头字体色彩，默认是蓝色
    background-color: aqua;
    border-bottom: 2px solid red;
    border-radius: 10px;
  }

  .el-tabs__active-bar {
     background-color: transparent;
}

    .el-tabs__item:hover {
    height: 60px;
    text-align: center;
    line-height: 5;
    border-radius: 10px;
    color: orangered; //批改鼠标挪动到表头时候字体色彩，默认淡蓝色
    cursor: pointer; //鼠标挪动到表头时候鼠标款式，默认小手
    background-color: pink;
  }
  }


.myinput ::v-deep,
.mytable ::v-deep {
  .el-input {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .el-input__inner {
    font-size: 14px;
    color: #333;
    background-color: aqua;
    border: 0.052vw solid red;
  }
}

.mytree ::v-deep {
  overflow: hidden;
  .el-tree-node {
    position: relative;
    padding-left: 0;
  }
  //节点有间隙，隐藏掉展开按钮就好了,如果觉得空隙没事可以删掉
  .el-tree-node__expand-icon.is-leaf {
    display: none;
  }
  .el-tree .el-tree-node__expand-icon.expanded {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .el-tree-node__label {
    width: auto;
    float: right;
    font-family: “Trebuchet MS”, Arial, Helvetica, sans-serif;
    font-size: 14px;
    margin: 10px;
    color: #1f1f1f;
    line-height: 1.302vw;
    letter-spacing: 0.052vw;
    &:hover {
      color: white;
    }
  }

  .el-tree-node:focus > .el-tree-node__content {
    background-color: pink; //背景色
  }
  .el-tree-node__content {
    &:hover {
      background: lightgreen;
      .el-tree-node__label {
        color: white;
      }
    }
  }

  .el-tree .el-icon-caret-right:before {
    content: "\e723";
    font-size: 16pt;
    color: green;
    position: absolute;
    left: 0;
    top: -0.25vw;
  }
  .el-tree .el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
    content: "\e722";
    font-size: 16pt;
    color: green;
    position: absolute;
    left: 0;
    top: -0.25vw;
  }
}

.mytable ::v-deep {
  .el-table .el-table__header-wrapper tr th {
    background-color: rgb(18, 47, 92) !important;
    color: rgb(255, 255, 255);
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    font-weight: normal;
  }
  .el-table .el-table__row {
    // background-color: rgb(18, 47, 92);
    // color: rgb(255, 255, 255);
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    font-weight: normal;
  }

  .el-table .el-table__body tr.current-row > td {
    background-color: lightblue !important;
    color: white;
  }
  .el-table .el-table__body tr:hover > td {
    background-color: lightgreen !important;
    color: red;
  }

  .el-table td,
  .el-table th.is-leaf {
    border-bottom: 0.052vw solid #8990a7;
    border-right: 0.052vw solid #8990a7;
  }

  .el-table__header tr,
  .el-table__header th {
    height: 2.083vw;
    padding: 0;
  }
  .el-table__body tr,
  .el-table__body td {
    height: 2.083vw;
    padding: 0;
  }

  .el-table {
    border: 0.052vw solid #1f1f1f;
  }

  .el-table--border::after,
  .el-table--group::after {
    width: 0;
  }
  .el-table::before {
    height: 0;
  }
}

.el-collapse ::v-deep .el-collapse-item {
  //background-color: #e9eef3 !important;
  padding: 0;
  margin: 0;
}

.odd_step ::v-deep .el-collapse-item__header {
  //background-color: #e9eef3 !important;
  color: lightseagreen;
  font-size: 0.729vw;
  padding-left: 1.042vw;
}

.even_step ::v-deep .el-collapse-item__header {
  //background-color: #e9eef3 !important;
  color: orangered;
  font-size: 0.729vw;
  padding-left: 1.042vw;
}

.el-collapse-item ::v-deep .el-collapse-item__wrap {
  margin-top: 0.521vw;
  // background-color: #e9eef3 !important;
}

.alarmsetting ::v-deep {
  .my-id-label {
    color: #1f1f1f;
    text-align: center;
  }

  .my-id-content {
    color: orangered;
    text-align: center;
  }

  .my-code-label {
    color: #1f1f1f;
    text-align: center;
  }

  .my-code-content {
    color: darkcyan;
    text-align: center;
  }

  .my-msg-label {
    color: #1f1f1f;
    text-align: center;
  }

  .my-msg-content {
    color: green;
    text-align: center;
  }

  .my-set-label {
    color: #1f1f1f;
    text-align: center;
  }

  .my-set-content {
    color: blue;
    text-align: center;
  }
}
</style>

<style lang="scss">
.create-isLoading {
  .el-loading-spinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.7);
    padding: 1.042vw;
    border-radius: 0.208vw;
    width: auto;
    text-align: center;
    position: absolute;

    i {
      color: #eee;
    }

    .el-loading-text {
      color: #dbe276;
      font-size: 0.833vw;
    }
  }
}
</style>